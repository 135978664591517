import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SEO from "../components/seo"
import { StaticImage } from 'gatsby-plugin-image'

const StyleSheet = styled.div`
  color: #666;
  & > h2 {
    // font-size: 20px;
    margin-top: 24px;
    margin-bottom: 12px;
    // color: darkslategrey;
    & > a {
      color: darkred;
    }
  }
  & > p {
    line-height: 1.2;
    margin-bottom: 10px;
  }
`

const Credits = styled.dl`
  margin: 0;
  & dt {
    display: inline-block;
    font-weight: bold;
  }
  & dt::after {
    content: ":";
  }
  & dd {
    display: inline;
    margin-left: 8px;
  }
  & dd::after {
    display: block;
    content: '';
  }
`

const Logos = styled.div`
  margin: 60px 0;
  display: flex;
  flex-flow: wrap;
  & > div {
    // max-width: 150px;
    // max-height: 100px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
`

const AudioLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
  justify-content: space-between;
  & > a {
    padding: 20px 0;
  }
`

const PodcastPage = () => {
  return (
    <div>
      <Layout active={'podcast'}>
        <SEO title="Podcast" />
        <StyleSheet>
          <h2 style={{color: 'darkred'}}>Het Lilian-eum</h2>
          
          
          <StaticImage objectFit="contain" src='../images/podcast-logo.jpg' />
          <br />
          <br />
          

          
          
          <p>Mijn schoonvader nodigde Liliane Vertessen begin jaren tachtig uit om een kunstwerk te installeren bij hem thuis. En dit in een ruimte naast zijn kabinet van psychiater. Het werd een Lilian-eum, een erotische kamer waarin kunstenares Liliane Vertessen zichzelf voordeed als vrouwelijk lustobject.</p>
          <p>Na de dood van mijn schoonvader belandt het Lilian-eum in mijn bureau. Maar wat moet ik er mee? Is dit werk - veertig jaar later - nog steeds relevant? Ik ga op onderzoek en besluit de kamer te renoveren, lukt het om dit kunstwerk terug tentoon te stellen?</p>
          <p>Ik zoek ook contact met Liliane. Ze leeft nog maar is gesteld op haar privacy. Is ze bereid om mee te werken aan deze podcast?</p>
          <p>In de podcast kom je meer te weten over Liliane Vertessen, haar werk, de kunst- wereld nu en in de jaren tachtig. Haar eigenzinnig, integer werk voedt het inclusiviteits-debat dat momenteel gaande is binnen de kunsten. Want Liliane schikt zich niet naar de verwachtingen, ze doet geen concessies en is dus punk.</p>
          <p>Het Lilian-eum brengt je terug naar de nachtelijke boudoir-cultuur van de jaren tachtig: de seksuele bevrijding en de positieve beleving van het vrouwelijke lichaam. De podcast neemt de erotische vorm van het kunstwerk over: Voldoende voyeuristisch om de verbeelding te prikkelen, maar ook misleidend. Het is huiselijk intiem, maar nooit eenduidig.</p>
          
          <p>De podcast ging in première op het DS-podcastfestival op 11/11/2023.</p>
          <p>Je kan hem beluisteren via onderstaande links:</p>

          <AudioLinks>
            <a href="https://open.spotify.com/show/1KPjYvHMCL3zH2Vyz4scR4?si=e1e164893797423e" target='_blank'>
              <StaticImage objectFit="contain" height='60' src='../images/listen-on-spotify-button.png' />
            </a>
            <a href="https://podcasts.apple.com/us/podcast/lilian-eum/id1615940688" target='_blank'>
              <StaticImage objectFit="contain" height='60' src='../images/apple-podcasts.png' />
            </a>
          </AudioLinks>
          <br />
          
          <b>Een podcast door Maaike Leyn</b>
          <Credits>
            <dt>Productie</dt><dd>Maaike Leyn</dd>
            <dt>Credits</dt><dd>Stijn Demeulenaere (audio-dramaturgie), Sam Serruys en Viktor De Greef (studio-werking De Grote Post)</dd>
            <dt>In samenwerking met</dt><dd>De Grote Post, Mu.Zee</dd>
            <dt>Met de steun van</dt><dd>de Vlaamse Gemeenschap, Sabam for culture</dd>
            <dt>Web-ondersteuning</dt><dd>Emmanuel Isebaert</dd>
            <dt>Fotografie</dt><dd>Giannina Urmeneta Ottiker</dd>
            <dt>Met dank aan</dt><dd>Liv Laveyne, Pascal Lervant, Mieke Mels, het vrt-archief, Kiem-festival, Chambres d'O, test-luisteraars (Daniella, Karlijn, Emmanuel, Karlien, Giannina, Annouk, Greet, Tom, ...)</dd>
            <dt>Een bijzondere dank u gaat naar alle interviewgasten</dt><dd>Phyline Deldycke, Anaïs Isebaert, Emmanuel Isebaert, Bart Debaere, Mieke Mels, Anny T'jampens,  Paul en Marie-Rose De Clercq, Francis De Beir, Philip Vandenberghe, Tom Nys, Leo Copers, Ria Pacquée, Elke Helbig (Zwart Huis), Bob Coppens, Tijs Lammar (Tick tack), Alain Verleysen, Frank Henderickx,  Dirk De Wachter, Rik Sadet, Bieke Demeester, Neon Elite ...</dd>
            <dt>Grafisch ontwerp</dt><dd>Mario Debaene</dd>
            <dt>Podcast-opleiding</dt><dd>Mijn leraars (Bart, Eva en Pieter) en enthousiaste mede-studenten</dd>
            <dt>Muziek en audio</dt><dd>
            Via ccMixter, Freesound en BBC-soundeffects: 
            Artemisstrong, Doxent, Martijn de Boer, Septahelix, Speck, Apoxode  
            Mellowarriar, Zagi, Mr Wolf, Radio on the shelf, Bertsz, Holizna, Javolenus, Sparky, Victor Natas, Tripjazz. Fragmenten uit het VRT-archief, RTBF-archief (met dank aan Sonuma).</dd>
            <dt>Presentatie</dt><dd>DS-podcastfestival (Ben Van Alboom, Yves Delepeleire), OKV, Collect, the ArtCouch (Frederic De Meyer), Radio 1 (Culture Club, Zandman)</dd>
          </Credits>

          <Logos>
            <StaticImage objectFit="contain" width='70' src='../images/DGP.png' />
            <StaticImage objectFit="contain" width='80' src='../images/MuZee.png' />
            <StaticImage objectFit="contain" width='160' src='../images/vlaanderen.png' />
            <StaticImage objectFit="contain" width='120' src='../images/sabam.png' />
            <StaticImage objectFit="contain" width='130' src='../images/SONUMA.png' />
          </Logos>

        </StyleSheet>
      </Layout>
    </div>
  )
}

export default PodcastPage
